/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A parasol in which the fabric is made up of photovoltaic sheets - something like these ", React.createElement(_components.a, {
    href: "http://gigaom.com/2013/07/10/nanosolar-is-now-selling-off-its-solar-assets/"
  }, "guys"), " were supposed to make. The end of the handle has a U.S. standard 120V electrical socket and maybe a USB socket as well."), "\n", React.createElement(_components.p, null, "This could be in various sizes, like a patio umbrella for stationary use, as well as the usual parasol size."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
